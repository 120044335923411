<script setup lang="ts">
import { ChevronDownIcon } from "@heroicons/vue/20/solid";

const emit = defineEmits(["update:value", "update:values"]);
const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  values: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  options: {
    type: Array as PropType<
      { name: string; value: string; [key: string]: any }[]
    >,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const selected = computed(() => {
  if (props.multiple) {
    if (props.values.length === 0) {
      return "Alle";
    } else if (props.values.length === 1) {
      return props.options.find((v) => v.value === props.values[0])?.name;
    } else {
      return "Mehrere";
    }
  }
  return props.options.find((v) => v.value === props.value)?.name;
  // return props.options.find((v) => v.value === props.value)?.name;
});

const changeValue = (value: string) => {
  if (props.multiple) {
    const tmp = props.values;
    if (tmp.includes(value)) {
      tmp.splice(tmp.indexOf(value), 1);
    } else {
      tmp.push(value);
    }
    emit("update:values", tmp);
  } else {
    emit("update:value", value);
  }
};
</script>

<template>
  <div class="rounded-full">
    <HeadlessPopover v-slot="{ open, close }" class="relative h-full">
      <HeadlessPopoverButton
        :class="[
          'group relative inline-flex h-full w-fit cursor-pointer items-center gap-x-1 whitespace-nowrap rounded-full border border-secondary px-4 py-2.5 text-sm hover:border-secondary-hover focus:outline-1 focus:outline-primary disabled:cursor-not-allowed disabled:border-secondary',
          open ? '' : '',
        ]"
        :disabled="props.disabled"
      >
        <span class="text-misc-text-subtle">{{ props.label }}:</span>

        {{ selected }}
        <ChevronDownIcon
          :class="open ? '' : ''"
          class="ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80"
          aria-hidden="true"
        />
      </HeadlessPopoverButton>

      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <HeadlessPopoverPanel
          class="absolute z-10 mt-1 max-h-[300px] w-[200px] transform divide-y divide-black divide-opacity-5 overflow-auto rounded-md bg-white ring-1 ring-black ring-opacity-5 drop-shadow-md focus:outline-none"
        >
          <div
            class="flex flex-col gap-y-1 overflow-hidden rounded-lg bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <template v-for="option in options" :key="option.value">
              <button
                class="inline-flex w-full items-center justify-between rounded-lg px-2.5 py-2 text-left text-sm transition-colors duration-150"
                :class="[
                  props.multiple
                    ? props.values.includes(option.value)
                      ? 'bg-alternative text-primary'
                      : 'hover:bg-tertiary-hover'
                    : props.value === option.value
                    ? 'bg-alternative text-primary'
                    : 'hover:bg-tertiary-hover',
                ]"
                @click="
                  [changeValue(option.value), props.multiple ? '' : close()]
                "
              >
                {{ option.name }}
              </button>
            </template>
          </div>
        </HeadlessPopoverPanel>
      </transition>
    </HeadlessPopover>
  </div>
</template>
